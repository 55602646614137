// i18next-extract-mark-ns-start pos-systems-for-restaurants
import React from "react";
import { ContactSalesButton } from "components/ContactSalesButton";
import { Content } from "components/Content";
import { List } from "components/List";
import { Section, SectionActions, SectionHeader, SectionImage } from "components/Section";
import { SEO } from "components/SEO";
import { GradientBox, GradientCtaImage, GradientSectionDescription } from "components/landings/GradientBox";
import { SignUpButton } from "components/SignUpButton";
import { graphql } from "gatsby";
import { BlogLink } from "components/links/Blog";
import { StyledDiv } from "components/StyledSx";
import { QrGroup } from "components/monei-pay/QrGroup";
import { HorizontalScreenshots } from "components/monei-pay/HorizontalScreenshots";
import { MainTitleContent } from "components/monei-pay/MainTitleContent";
import { SupportLink } from "components/links/Support";
import { InternalPageLink } from "components/links/Pages";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";
import { DownloadOnAppleStore, IosDownloadLink } from "components/DownloadOnAppleStore";
import { LanguageEnum } from "../../../locales/types";
import { AndroidDownloadLink, DownloadOnGooglePlay } from "components/DownloadOnGooglePlay";
import Bold from "components/Bold";
import { MoneiVideoSection } from "components/landings/MoneiVideoSection";
import { CompatibleWith } from "components/monei-pay/CompatibleWith";
import { Flex } from "components/Flex";
import IndexBackground from "components/monei-pay/IndexBackground";
import monei_pay_qr_en from "images/monei_pay_qr_en.png";
import monei_pay_qr_es from "images/monei_pay_qr_es.png";
import "keen-slider/keen-slider.min.css";
import integrated_payments_header_image from "images/integrated_payments_header_image.png";
import { ButtonLink } from "components/Button";

const MoneiPay: React.FC = () => {
  const {t, language} = useI18next();
  const scanToPay = language === LanguageEnum.en ? monei_pay_qr_en : monei_pay_qr_es;
  return (
    <>
      <SEO
        title={t('POS Systems for Restaurants')}
        description={t(
          'Save time and money while avoiding bulky and unreliable POS systems for restaurants by accepting payments from your phone. Get MONEI Pay ››'
        )}
      />

      <IndexBackground>
        <Content>
          <Section columnLg>
            <MainTitleContent sx={{maxWidth: {all: '590px', lg: '100%'}}}>
              <SectionHeader underline tagName="h1">
                <Trans>Best alternative POS system for restaurants</Trans>
              </SectionHeader>
              <Trans parent="p">
                Tired of dealing with high costs, frequent network issues, short battery life, and not
                having enough devices for your waitstaff? Ditch the expensive POS systems for
                restaurants and bars to quickly and securely charge customers from any mobile device.
              </Trans>
              <Trans parent="p">
                Improve the payment experience in your restaurant, while saving time and money with
                MONEI Pay.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Get MONEI Pay Now</Trans>
                </SignUpButton>
                <ContactSalesButton />
                <CompatibleWith />
              </SectionActions>
            </MainTitleContent>
            <MoneiVideoSection />
          </Section>
        </Content>
      </IndexBackground>
      <Content sx={{marginTop: {md: '20px'}}}>
        <Section centered sx={{paddingTop: '20px'}}>
          <SectionImage
            style={{width: '455px'}}
            mobileWidth={370}
            src={scanToPay}
            alt="Scan to Pay"
            title="Scan to Pay"
            className="hide-on-mobile"
          />
          <div>
            <SectionHeader underline style={{marginTop: '10px'}}>
              <Trans>Accept payments without a POS in your restaurant</Trans>
            </SectionHeader>
            <Trans parent="p">
              The best POS system for restaurants is not a traditional point of sale. Skip the bulky
              hardware and use a simple, secure, and cheaper payment system to charge customers from
              your phone or tablet using digital QR codes.
            </Trans>
            <Trans parent="p">
              <Bold>Unlike traditional POS systems, with MONEI Pay, you can:</Bold>
            </Trans>
            <List>
              <Trans parent="li">
                Save money on hardware and <InternalPageLink slug="pricing">transaction fees</InternalPageLink> with a
                payment solution that’s 50% cheaper than traditional restaurant POS systems{' '}
              </Trans>
              <Trans parent="li">
                Accept <InternalPageLink slug="payment-methods">many payment methods</InternalPageLink>:{' '}
                <InternalPageLink slug="payment-methods/credit-cards">credit cards</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>, and{' '}
                <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>{' '}
                (in Spain){' '}
              </Trans>
              <Trans parent="li">
                Offer a <BlogLink slug="frictionless-payments">seamless payment</BlogLink>{' '}
                experience
              </Trans>
              <Trans parent="li">
                Equip your entire restaurant waitstaff with a{' '}
                <BlogLink slug="benefits-of-mobile-payments">one device</BlogLink> to accept orders
                and process payments{' '}
              </Trans>
              <Trans parent="li">
                Let groups split payments by creating multiple QR code payments for one bill
              </Trans>
            </List>
            <Trans parent="p">👇 Download your mobile payment app today.</Trans>
            <Flex>
              <div style={{marginRight: '16px'}}>
                <DownloadOnAppleStore  />
              </div>
              <DownloadOnGooglePlay />
            </Flex>
          </div>
        </Section>
      </Content>
      <Content>
        <Section textAlign="center">
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <SectionHeader style={{fontSize: '2rem'}}>
              <Trans>
                How does <Bold>MONEI Pay</Bold> for restaurants work?
              </Trans>
            </SectionHeader>
            <Trans parent="p">
              <SupportLink slug="/articles/4417692136977">
                MONEI Pay
              </SupportLink>{' '}
              is a simple yet powerful solution for restaurants and bars that lets you acccept
              payments using{' '}
              <SupportLink slug="/sections/360005899978">
              digital QR codes
              </SupportLink>
              . All you have to do is open the app on your mobile device and add the payment amount.
              Once the digital QR payment code appears on the screen, the customer can scan it with
              their smartphone, select their preferred payment method, and complete the payment.
            </Trans>
            <SignUpButton variant="light">
              <Trans>Try MONEI Pay</Trans>
            </SignUpButton>
          </div>
        </Section>
      </Content>
      <Content fullWidth>
        <HorizontalScreenshots />
      </Content>

      <Content>
        <Section reverseOnMobile centered sx={{paddingTop: '40px'}}>
          <QrGroup />
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>Start accepting restaurant payments from your phone</Trans>
            </SectionHeader>
            <Trans parent="p">
              <Bold>Creating your MONEI Pay account is simple.</Bold> Open an account, then download
              the{' '}
              <AndroidDownloadLink>
                Android
              </AndroidDownloadLink>{' '}
              or{' '}
              <IosDownloadLink>iOS</IosDownloadLink>{' '}
              mobile payment app on as many devices as you need to start generating digital QR code
              payments for your restaurant.
            </Trans>
            <StyledDiv sx={{display: {all: 'inline', sm: 'none'}}}>
              <Trans parent="p">
                👈 <Bold>Scan any QR code to test the payment experience.</Bold>
              </Trans>
            </StyledDiv>
            <SignUpButton variant="light">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </div>
        </Section>
      </Content>
      <Content>
        <GradientBox wrapperStyle={{overflow: 'visible', marginTop: '60px'}}>
          <SectionHeader tagName="h3" underline style={{color: 'white'}}>
            <Trans>Do you have a platform or marketplace for restaurants, bars, and cafes?</Trans>
          </SectionHeader>
          <GradientSectionDescription>
            <Trans parent="p">
              Use MONEI Connect to integrate payments and help your users accept more payment methods in person or online.
            </Trans>
          </GradientSectionDescription>
          <SectionActions>
            <ButtonLink href="/connect/" variant="dark">
              <Trans>Discover MONEI Connect</Trans>
            </ButtonLink>
          </SectionActions>
          <GradientCtaImage
            sx={{
              maskImage: 'linear-gradient(180deg, #000000 94%, rgba(0, 0, 0, 0) 100%)',
              bottom: '0',
              right: '70px',
              width: '25%',
            }}
            src={integrated_payments_header_image} />
        </GradientBox>
      </Content>
    </>
  );
};

export default MoneiPay;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "pos-systems-for-restaurants"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
